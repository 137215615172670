import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Link, Card, CardContent, RadioGroup, FormControlLabel, Radio, Chip, TextField } from '@material-ui/core';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import Button from 'components/Button'
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import API from 'api';
import colors from 'appcolors';
import globals from 'appglobals';
import Divider from 'components/Divider';
import { BagContext } from 'contexts/bag.context';
import { coupon_info_pipe, number_format_pipe } from 'pipes';
import useOrderFormStyles from './useOrderFormStyles';
import EachItem from './EachItem';
import PurchaserInfo from './PurchaserInfo';
import ReceiverInfo from './ReceiverInfo';
import { AuthContext } from 'contexts/auth.context';
import OrderFormContext from './order-form.context';
import { AppContext } from 'contexts/app.context';
import CircularButton from 'components/CircularButton';
import CouponSelectionModal from './CouponSelectionModal';
import { MemoryContext } from 'contexts/memory.context';
import { useCookies } from 'react-cookie';


const OrderFormPage = () => {
	const classes = useOrderFormStyles();
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { showAlert, showDialog } = useContext(AppContext);
    const { me } = useContext(AuthContext);
    const { stuff } = useContext(BagContext);
    const { urlCoupon } = useContext(MemoryContext);

    // 쇼핑백에 있는 상품 상세정보
    const [ items, setItems ] = useState();
    useEffect(() => {
        if (stuff?.length > 0) {
            API.post('/bag/get-stuff', stuff)
            .then(setItems);
        }
        else {
            setItems([]);
        }
    }, [ stuff ]); 
    // end

    // coupon by cookie, 쿠키에 의해 검출된 쿠폰
    const [cookies, setCookie] = useCookies(['coupon_uuid']);
    const [cookieCoupon, setCookieCoupon] = useState(null);
    useEffect(() => {
        if (cookies?.coupon_uuid) {
            API.get(`/coupon/coupon-by-uuid/${cookies?.coupon_uuid}`)
            .then(coupon => {
                if (!coupon?.usedAt) setCookieCoupon(coupon);
            })
        }
    }, [cookies?.coupon_uuid])

    // coupon
    const { availableCoupons: myCoupons } = useContext(MemoryContext);
    const [ coupons, setCoupons ] = useState([]);       // 구매시 적용되는 쿠폰 (여러개일 수 있음)
    const [ couponModalOpen, setCouponModalOpen] = useState(false);
    useEffect(() => {
        if (cookieCoupon) setCoupons([cookieCoupon]);
        else if (urlCoupon) setCoupons([urlCoupon]);
    }, [ urlCoupon, cookieCoupon  ]);
    const handleCouponSelected = (coupon) => {
        if (!!coupons.find(el => el.id == coupon.id)) return showAlert('이미 선택된 쿠폰입니다.');
        // 중복 선택 가능한 쿠폰 code

        const coupons_a = [ ...coupons, coupon ];

        const allowOverlap = [ 'thankyou' ];
        let notAllowOverlapCoupons = [];
        for (let coupon of coupons_a) {
            if (allowOverlap.indexOf(coupon.code) === -1) {
                notAllowOverlapCoupons.push(coupon);
            }
        }
        if (notAllowOverlapCoupons.length > 1) return showAlert('중복가능하지 않은 쿠폰이 두개 이상 포함될 수 없습니다.');
        
        setCoupons(coupons_a);
    }
    const excludeCoupon = (coupon) => () => {
        setCoupons((prevs) => {
            return prevs.filter(el => el !== coupon);
        })
    }
    // end: coupon

    // iamport
    const callIamport = (values) => {
        const name = (items.length === 1) ? items[0].name : `${items[0].name} 외 ${items.length - 1}종`
        const address = `${values.address}, ${values.addressDetail}`;

        const data = {
            pg: "html5_inicis",
            pay_method: 'card',    // card, vbank, trans
            merchant_uid: values.merchantId,
            name: name,
            amount: process.env.REACT_APP_MODE === 'real' ? productPriceInfo.discounted + deliveryPriceInfo.deliveryPrice : 100,
            buyer_email: values.email,
            buyer_name: values.name,
            buyer_tel: values.mobile,
            buyer_addr: address,
            buyer_postcode: values.zipcode,
            m_redirect_url: `${process.env.REACT_APP_HOST}/hook/mobile-pay-done`,
        };

        const { IMP } = window;
        IMP.init(globals.iamport.id);
        IMP.request_pay(data, (rsp) => {
            // 모바일의 경우, 여기로 분기하지 않고 m_redirect_url 로 이동함.
            const { success, imp_uid, merchant_uid, error_msg } = rsp;
            if (!success) {
                const message = error_msg || '에러가 발생했습니다.';
                showAlert(message);
                return;
            }

            // iamport 가 hook을 실서버로 보내므로 개발환경에서는 여기서 처리
            if (process.env.NODE_ENV === 'development') {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_HOST}/hook/iamport`,
                    data: { imp_uid, merchant_uid },
                })
                .catch(error => {
                    showAlert(error.message);
                })
            }

            history.push('/order/success/' + merchant_uid);
        });
    }
    //  end: iamport

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: me?.email || '',
            name: '',
            mobile: me?.mobile || '',
            zipcode: '',
            address: '',
            addressDetail: '',
            memo: '',
            method: 'card',
            accountHolder: '',
        },
        validationSchema: yup.object({
            name: yup.string().required('이름은 필수입력입니다.'),
            mobile: yup.string().max(20, '핸드폰번호 형식에 맞게 입력해주세요.'),
            zipcode: yup.string().required('주소는 필수입력입니다.'),
            address: yup.string().required('주소는 필수입력입니다.'),
            addressDetail: yup.string().required('상세주소는 필수입력입니다.'),
            memo: yup.string().max(100, '배송 요청사항은 최대 100자까지 입력가능합니다.'),
            email: yup.string().email('이메일 형식에 맞지 않습니다.'),
            accountHolder: yup.string().max(32, '계좌주 성함이 너무 깁니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (!me) {
                values.stuff = stuff;
            }
            if (!values.mobile && !values.email) {
                showAlert('이메일 또는 핸드폰번호 둘 중 하나를 입력해주세요.');
                return setSubmitting(false);
            }
            if (values.method === 'transfer') {
                if (!values.mobile) {
                    showAlert('연락처를 입력해주세요.');
                    return setSubmitting(false);
                }
                if (!values.accountHolder) {
                    showAlert('계좌이체하실 계좌의 계좌주 성함을 입력해주세요.');
                    return setSubmitting(false);
                }
            }
            if (coupons.length) values.couponIds = coupons.map(coupon => coupon.id);
            
            API.post('/order/order', values)
            .then(({ merchantId, price, deliveryPrice }) => {
                if (productPriceInfo.discounted !== price) return showAlert('가격정보가 상이합니다. 다시 주문해주세요.');
                if (deliveryPriceInfo.deliveryPrice !== deliveryPrice) return showAlert('배송비정보가 상이합니다. 다시 주문해주세요.');

                if (values.method === 'card') {
                    values.merchantId = merchantId;
                    callIamport(values);
                }
                else {
                    history.push('/order/success/' + merchantId);
                }
            })
            .finally(() => { setSubmitting(false); })
        },
    });
    const { values, isSubmitting, handleSubmit, errors, submitCount, setFieldValue, getFieldProps } = formik;

    const [ scrollBlock, setScrollBlock ] = useState(false);
    useEffect(() => {
        if (submitCount > 0) {
            setScrollBlock(false);
        }
    }, [ submitCount ]);

    useEffect(() => {
        if (submitCount > 0 && !scrollBlock) {
            const keys = Object.keys(errors);
            const key = keys.length > 0 && keys[0];
            
            nameRef.current.scrollIntoView();
        }
    }, [ submitCount, errors, scrollBlock ]);
    const nameRef = useRef();


    // 가격결정
    const [ productPriceInfo, setProductPriceInfo ] = useState();
    const [ deliveryPriceInfo, setDeliveryPriceInfo ]  = useState();
    useEffect(() => {
        if (stuff) {
            const data = {
                stuff,
                couponIds: coupons.map(coupon => coupon.id)
            };
            API.post('/item/get-product-price-info', data)
            .then(setProductPriceInfo);
        }
    }, [ stuff, coupons ]);

    useEffect(() => {
        if (values.address && values.zipcode) {
            const params = { zipcode: values.zipcode, address: values.address };
            API.get('/address/check-delivery-price', { params })
            .then(setDeliveryPriceInfo);
        }
    }, [ values.address ])
    const deliveryAmountText = useMemo(() => {
        if (deliveryPriceInfo) {
            const amount = deliveryPriceInfo.deliveryPrice;
            if (amount === null) return '-';
            if (amount === 0) {
                if (deliveryPriceInfo.membership) return '무료 (프리미엄 멤버십 - 무료배송)';
                else return '무료';
            }
            else return number_format_pipe(amount);
        }
    }, [ deliveryPriceInfo ]);
    const totalAmountText = useMemo(() => {
        if (productPriceInfo && deliveryPriceInfo) {
            return number_format_pipe(productPriceInfo.discounted + deliveryPriceInfo.deliveryPrice) + '원';
        }
        return '';
    }, [ productPriceInfo, deliveryPriceInfo ])
    // end

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isMobile ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <OrderFormContext.Provider value={formik}>
                <form>
                    <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" paddingX={2}>
                        <Card style={{ flex: 1 }}>
                            <CardContent>
                                <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>주문 / 결제</Typography>
                                <Divider />

                                <div style={{ marginTop: '8px', backgroundColor: colors.primaryDark, padding: '4px 16px' }}>
                                    <Link variant="body1" style={{ color: 'white', textDecoration: 'none' }} component={'a'} href="/items">아직 쇼핑이 끝나지 않으셨나요? 상품조회 페이지로 이동하시겠습니까?</Link>
                                </div>

                                <div ref={nameRef}></div>
                                {me && <>
                                    <PurchaserInfo />
                                    <Divider />
                                </>}
                                
                                <ReceiverInfo />
                                <Divider />

                                {items && productPriceInfo && <>
                                    <div className={classes.section}>
                                        <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>상품정보</Typography>
                                        <div>
                                            {items.map((item, index) => <EachItem key={index} item={item} />)}
                                            {items.length === 0 && <Typography variant='body2' color="error">쇼핑백에 상품이 없습니다.</Typography>}
                                            {productPriceInfo.eventCount === 1 && productPriceInfo.totalCount < 2 && <Typography variant='body2' color="error">1+1 이벤트 할인을 받으려면 상품이 두개이상 주문되어야 합니다. (상품종류 관계없음)</Typography>}
                                        </div>
                                    </div>
                                    <Divider />
                                </>}

                                <div className={classes.section}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline'}}>
                                        <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>결제정보</Typography>
                                        {myCoupons?.length > 0 && <Button size="small" variant='outlined' color="secondary" onClick={() => { setCouponModalOpen(true) }}>쿠폰사용</Button>}
                                    </div>
                                    <div className={classes.table}>
                                        <div className={classes.row}>
                                            <div className={classes.label}>
                                                <Typography variant="body1" className={classes.labelText}>결제옵션</Typography>
                                            </div>
                                            <div className={classes.content}>
                                                <Typography variant="body1">일시불</Typography>
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.label} style={{ flexDirection: 'row' }}>
                                                <Typography variant="body1" className={classes.labelText}>상품가격</Typography>
                                                
                                            </div>
                                            <div className={classes.content} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <Typography variant="body1">{number_format_pipe(productPriceInfo?.discounted)}원</Typography>
                                                    {productPriceInfo?.discount && <Typography variant="body2" style={{ marginLeft: '4px', color: colors.textSecondary }}>{productPriceInfo.discount.rate > 0 ? `(${productPriceInfo.discount.rate}% 할인)` : ''}</Typography>}
                                                </div>
                                                <div style={{ display: 'flex'}}>
                                                    {coupons.map(coupon => <Chip
                                                        key={coupon.id}
                                                        style={{ marginLeft: '8px' }}
                                                        size='small'
                                                        color="secondary"
                                                        variant='outlined'
                                                        label={coupon_info_pipe(coupon).name}
                                                        onClick={excludeCoupon(coupon)}
                                                        onDelete={excludeCoupon(coupon)}
                                                    />)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.label}>
                                                <Typography variant="body1" className={classes.labelText}>배송비</Typography>
                                            </div>
                                            <div className={classes.content}>
                                                <Typography variant="body1">{deliveryAmountText}</Typography>
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.label}>
                                                <Typography variant="body1" className={classes.labelText}>결제금액</Typography>
                                            </div>
                                            <div className={classes.content}>
                                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{totalAmountText}</Typography>
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.label}>
                                                <Typography variant="body1" className={classes.labelText}>결제방법</Typography>
                                            </div>
                                            <div className={classes.content}>
                                                <RadioGroup name="method" value={values.method} row onChange={(event) => { setFieldValue('method', event.target.value); }}>
                                                    <FormControlLabel value="card" control={<Radio size='small' />} label="신용/체크카드" />
                                                    <FormControlLabel value="transfer" control={<Radio size='small' />} label="계좌이체" />
                                                </RadioGroup>
                                            </div>
                                        </div>
                                        {values.method === 'transfer' && <>
                                            <div className={classes.row}>
                                                <div className={classes.label}>
                                                    <Typography variant="body1" className={classes.labelText}>계좌주</Typography>
                                                </div>
                                                <div className={classes.content} style={{ padding: '4px', paddingRight: '8px' }}>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        variant="outlined"
                                                        className={classes.inputContent}
                                                        placeholder="사용하실 계좌의 계좌주 성함 입력"
                                                        required={true}
                                                        size="small"
                                                        {...getFieldProps('accountHolder')}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.row}>
                                                <div className={classes.content}>
                                                    <Typography variant="body2">* 입금이 확인되면 배송진행을 도와드립니다.</Typography>
                                                    <Typography variant="body2">* <span style={{ fontWeight: 'bold' }}>계좌번호</span>는 주문하기 버튼을 클릭하시면. <span style={{ fontWeight: 'bold' }}>문자메시지</span>로 전송해 드립니다.</Typography>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </div>

                                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                    <CircularButton loading={isSubmitting} variant="contained" size="large" color="primary" buttonStyle={{ color: 'white', paddingLeft: '36px', paddingRight: '36px' }} onClick={handleSubmit}>{values.method === 'card' ? '결제하기' : '주문하기'}</CircularButton>
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                </form>
            </OrderFormContext.Provider>

            <CouponSelectionModal open={couponModalOpen} setOpen={setCouponModalOpen} myCoupons={myCoupons} onCouponSelected={handleCouponSelected} />
		</div>
	);
}

export default OrderFormPage;