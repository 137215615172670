import React from 'react';
import { Container, Typography, makeStyles, useTheme, useMediaQuery, Link, Divider } from '@material-ui/core';
import { useHistory, Link as RouteLink } from 'react-router-dom';

import colors from 'appcolors';




const useStyles = makeStyles((theme) => ({
	verticalSpan: { margin: '2px 12px', borderRight: '1px solid #bdbdbd', alignSelf: 'stretch' },
}));


// 디자인: apple.com 참조 
const Footer = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	

	return (
        <div style={{ borderTop: '1px solid #dddddd', padding: '16px 0' }}>
            <Container>
				{/* 진혜가 빼라고 함 */}
				{/* <div style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography variant={isHandset ? 'body2' : 'body1'}>상호명: (주)명문스포츠</Typography>
					<Typography variant={isHandset ? 'body2' : 'body1'}>주소: 부산광역시 북구 구포동 1184-21</Typography>
					<Typography variant={isHandset ? 'body2' : 'body1'}>사업자등록번호: 606-81-87314</Typography>
					<Typography variant={isHandset ? 'body2' : 'body1'}>통신판매업신고번호: 제 2017-부산북구-0182호</Typography>
					<Typography variant={isHandset ? 'body2' : 'body1'}>대표자명: 박무영</Typography>
				</div> */}
				{/* <Divider style={{ marginTop: '12px', marginBottom: '12px' }} /> */}

				<div style={{ display: 'flex', flexDirection: isHandset ? 'column' : 'row', alignItems: isHandset ? 'flex-start' : 'center' }}>
					<Typography variant="body2" color="textSecondary">&copy;&nbsp;MungMoonSports, All Right Reserved.</Typography>
					
					<div style={{ display: 'flex', alignItems: 'center', marginTop: isHandset ? '8px' : 0, marginLeft: isHandset ? 0 : '24px' }}>
						<Link component={RouteLink} to="/" variant="body2" style={{ color: colors.textPrimary, fontSize: '.8em' }}>Home</Link><div className={classes.verticalSpan} />
						<Link href={`${process.env.REACT_APP_HOST}/resource/policy/app_policy`} variant="body2" style={{ color: colors.textPrimary, fontSize: '.8em' }} target="_blank">Terms</Link><span className={classes.verticalSpan} />
						<Link href={`${process.env.REACT_APP_HOST}/resource/policy/privacy_policy`} variant="body2" style={{ color: colors.textPrimary, fontSize: '.8em' }} target="_blank">Privacy</Link><span className={classes.verticalSpan} />
						<Link component={RouteLink} to="/inquiry-home" variant="body2" style={{ color: colors.textPrimary, fontSize: '.8em' }}>Contact</Link><div className={classes.verticalSpan} />
						<Link component={RouteLink} to="/membership" variant="body2" style={{ color: colors.textPrimary, fontSize: '.8em' }}>Membership</Link>
					</div>
				</div>

            </Container>
        </div>
	);
}

export default Footer;